import { react, useEffect, useState } from 'react'
import { useStore } from '../../context/GlobalState';
import { buyNFT } from '../../store/asyncActions';
import './Body1.css';
import logo from './logo.png'
import { setupWeb3 } from '../../store/actions';
import { loadBlockchain } from "../../store/asyncActions";

const Body1 = () => {
    const [{ web3, contract, accounts }, dispatch] = useStore();
    const [mintValue, setMintValue] = useState(0);
    const [nftPrice, setNFTPrice] = useState(0);


    useEffect(() => {
        console.log("state in body1", contract);


    }, [contract])
    const handleMint = async () => {
        console.log("inHandle Mint");
        if (web3 && contract) {
            let price = mintValue * 250;
            let amount = web3.utils.toWei(price.toString(), 'ether');//250*10**18//
            //  const amount=price*mintValue;
            let resp = await buyNFT(web3, contract, mintValue, amount, accounts);
        }
    }
    const handleChange = async (value) => {
        var newvalue = (value < 0) ? 0 : value;
        //     newvalue=(value>3)?3:value;
        setMintValue(newvalue);
        console.log("inHandle Change", mintValue);

    }
    const handleConnect = async () => {
        loadBlockchain(dispatch);
    }

    const handleLogout = () => {
        dispatch(setupWeb3(null))
      }


    return (
        <div>

            <div class="body1">
                <img class="logo" src={logo} alt="" />
                <p class="text">NFT Required</p>
                <form action="" method="post">
                    <input class="textbox" type="number" value={mintValue} name="textbox" id="" onChange={(e) => handleChange(e.target.value)} />
                </form>
                <p class="cost">Each NFT cost 250 Matic</p>
                <button class="button" onClick={handleMint}>Mint</button>
                {
                    web3?
                    <button class="button2" onClick={handleLogout}>Logout</button>
                    :
                    <button class="button2" onClick={handleConnect}>Connect with Metamask</button>

                }
            </div>

        </div>
    );

}
export default Body1