import { setupWeb3, setupContract, setNetwork, addEthereumAccounts, web3LoadingError, navigationAction } from "./actions";
import Web3 from "web3";
import { PURIPETS_CONTRACT_ABI, PURIPETS_CONTRACT_ADDRESS } from '../contract/PuriPetsABI';



export const loadBlockchain = async (dispatch) => {
    try {
        console.log("Web3 = ", Web3);
        console.log("Web3.givenProvider = ", Web3.givenProvider);
        dispatch(setNetwork(Web3.givenProvider.chainId));
        if (Web3.givenProvider) {
            // if (Web3.givenProvider && Web3.givenProvider.chainId == 0x38	) {
            window.ethereum.on('accountsChanged', function (accounts) {
                dispatch(addEthereumAccounts(accounts));
            });
            const web3 = new Web3(Web3.givenProvider);
            await Web3.givenProvider.enable();
            dispatch(setupWeb3(web3));
            const contract = new web3.eth.Contract(PURIPETS_CONTRACT_ABI, PURIPETS_CONTRACT_ADDRESS);
            dispatch(setupContract(contract));
            const accounts = await web3.eth.getAccounts();
            dispatch(addEthereumAccounts(accounts));
            console.log("contract", contract);
            console.log("puripetcontract", contract.methods)

        }
        else {
            dispatch(web3LoadingError("Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"))
        }
        if (Web3.givenProvider && Web3.givenProvider.chainId != 0x89) {//changed from 0x38
            alert("please connect wallet with Polygon Network")
        }
    }
    catch (error) {
        console.log("Error in loading Web3 = ", error);
        if (error.code === 4001) {

            dispatch(web3LoadingError(error.message));
        }
    }
}


export const buyNFT = async (web3, contract, qty, amount, accounts) => {
    const value = web3.utils.toBN(amount).toString();
    console.log("BN in buy", value);
    try {
        let resp = await contract.methods.buyMany(qty, accounts[0]).send({ from: accounts[0], value: amount });
        console.log("Receipt after buy", resp);
    } catch (error) {
        console.log("Error in Buy", error);
    }
}
