import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Body1 from './Components/Body1/Body1';
import Body2 from './Components/Body2/Body2';
import Body3 from './Components/Body3/Body3';
import Footer from './Components/Footer/Footer'

function App() {
  return (
    <div className="App">

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {/* <Header/> */}
      <Body1 />
      {/* <Body2/> */}

      {/* <Body3/> */}
      {/* <Footer/> */}

    </div>
  );
}

export default App;
